import React from 'react';

const GiroEIcon = (
    props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.JSX.Element => {
    return (
        <svg {...props} viewBox="0 0 51 12">
            <defs id="defs120" />
            <g id="layer1" transform="translate(-3.8135104,-3.3994273)">
                <path
                    d="m 9.6237254,8.5277573 h 4.5315716 v 5.8098997 c -0.852487,0.52564 -2.088303,0.88089 -3.963247,0.88089 -3.4663236,0 -6.3785396,-1.97485 -6.3785396,-5.8670497 0,-3.36656 2.429228,-5.95207 6.3925806,-5.95207 1.676259,0 3.238959,0.36936 3.934989,0.80963 l -0.198966,2.54282 c -1.008451,-0.61066 -2.216045,-0.82374 -3.665044,-0.82374 -2.2160086,0 -3.5371616,1.0795 -3.5371616,3.33834 0,2.3438497 1.264214,3.4801497 3.5798826,3.4801497 0.340925,0 0.696031,-0.0141 1.051172,-0.0423 v -1.76177 H 9.6237254 V 8.5277873"
                    style={{
                        fill: '#1e355e',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                        strokeWidth: 0.0352778,
                    }}
                    id="path76"
                />
                <path
                    d="m 16.171634,3.3994273 h 2.812697 v 2.51425 h -2.812697 z m -0.823948,3.69323 h 3.636645 V 14.991357 H 16.171634 V 9.3800673 h -1.108075 l 0.284127,-2.28741"
                    style={{
                        fill: '#1e355e',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                        strokeWidth: 0.0352778,
                    }}
                    id="path78"
                />
                <path
                    d="m 20.560684,7.0926573 2.798515,-0.2413 v 1.57692 c 0.653451,-0.86642 1.420496,-1.57692 2.571151,-1.57692 0.213184,0 0.397686,0.0145 0.582507,0.0282 l -0.02826,2.88396 c -0.412185,-0.0995 -0.852593,-0.11359 -1.165013,-0.11359 -0.738611,0 -1.321259,0.11359 -1.960387,0.3979297 v 4.94348 H 20.560682 V 7.0926373"
                    style={{
                        fill: '#1e355e',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                        strokeWidth: 0.0352778,
                    }}
                    id="path80"
                />
                <path
                    d="m 31.327991,13.030617 c 1.121975,0 1.5627,-0.65335 1.5627,-2.0313 0,-1.3920597 -0.454766,-2.0030697 -1.576917,-2.0030697 -1.122256,0 -1.548482,0.5969 -1.548482,1.9748497 0,1.39206 0.440266,2.05952 1.562699,2.05952 z m 0,-6.1792597 c 3.110901,0 4.290131,1.74731 4.290131,4.1906497 0,2.4578 -1.165013,4.19065 -4.27609,4.19065 -3.082396,0 -4.30403,-1.70463 -4.30403,-4.19065 0,-2.4715597 1.207452,-4.1906497 4.289989,-4.1906497"
                    style={{
                        fill: '#1e355e',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                        strokeWidth: 0.0352778,
                    }}
                    id="path82"
                />
                <path
                    d="m 37.7108,9.9935473 c 0,0 -0.813858,0 -0.813858,0.8138597 v 0.5902 c 0,0 0,0.8135 0.813858,0.8135 h 4.312003 c 0,0 0.813859,0 0.813859,-0.8135 v -0.5902 c 0,0 0,-0.8138597 -0.813859,-0.8138597 H 37.7108"
                    style={{
                        fill: '#bedd20',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                        strokeWidth: 0.0352778,
                    }}
                    id="path84"
                />
                <path
                    d="m 37.7108,6.8538273 c 0,0 -0.813858,0 -0.813858,0.81386 v 0.5902 c 0,0 0,0.8135 0.813858,0.8135 h 4.312003 c 0,0 0.813859,0 0.813859,-0.8135 v -0.5902 c 0,0 0,-0.81386 -0.813859,-0.81386 H 37.7108"
                    style={{
                        fill: '#1e355e',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                        strokeWidth: 0.0352778,
                    }}
                    id="path86"
                />
                <path
                    d="m 37.7108,13.028147 c 0,0 -0.813858,0 -0.813858,0.81386 v 0.59019 c 0,0 0,0.81386 0.813858,0.81386 h 4.312003 c 0,0 0.813859,0 0.813859,-0.81386 v -0.59019 c 0,0 0,-0.81386 -0.813859,-0.81386 H 37.7108"
                    style={{
                        fill: '#1e355e',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                        strokeWidth: 0.0352778,
                    }}
                    id="path88"
                />
                <path
                    d="m 46.864678,8.3859373 h 4.896909 c 0.05997,-0.17991 0.07973,-0.39969 0.07973,-0.57961 0,-1.57903 -1.099256,-2.13854 -2.238375,-2.13854 -1.438981,0 -2.498373,0.87948 -2.738262,2.71815 z m 0,1.7790597 c 0.279753,1.97873 1.618898,2.59821 3.477684,2.59821 1.159581,0 2.538589,-0.16016 3.777545,-0.43991 l -0.259998,2.35867 c -0.419452,0.1397 -1.818569,0.51964 -3.73768,0.51964 -4.376915,0 -6.175376,-1.99884 -6.175376,-5.6165697 0,-3.85728 1.918406,-6.17573 5.835651,-6.17573 2.818341,0 4.857044,1.15923 4.857044,4.49686 0,0.63958 -0.08008,1.47884 -0.21978,2.2588297 h -7.55509"
                    style={{
                        fill: '#bedd20',
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        stroke: 'none',
                        strokeWidth: 0.0352778,
                    }}
                    id="path90"
                />
            </g>
        </svg>
    );
};

export default GiroEIcon;
