import React from 'react';
import {TFunction} from 'i18next';
import {SubmitFunction} from 'formifly';
import {Alert, Button, Card, CardHeader, Grid, Typography} from '@mui/material';
import {IconButtonWithTooltip} from '@common/butterfly-shared-react-library';
import {DeleteOutlined, EditOutlined} from '@mui/icons-material';
import {
    createRegistrationCredential,
    createWebAuthNDevice,
    enableWebAuthNDevice,
    getCreateWebAuthNDeviceShape,
    getDeleteWebAuthNDeviceShape,
    getEditWebAuthNDeviceShape,
} from '@/Areas/Customer/Helpers/CustomerHelpers';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import {
    ActionAreaContainer,
    ActionButtonContainer,
    MarginGrid,
    TextWrappableCardHeader,
} from '@/Areas/Customer/Components/AccountSecurityOverviewComponents';
import {getCurrentLocale} from '@/Extensions/i18n';
import WebAuthNModals from '@/Areas/Customer/Components/WebAuthNModals';
import CardContentWithoutPadding from '@/Components/Layout/CardContentWithoutPadding';
import {webAuthNDeviceType} from '@/Areas/Customer/Data/Customer';

type WebAuthNOverviewPropType = {
    t: TFunction<['common', 'account', 'auth', 'formifly']>,
    webAuthNDevices: webAuthNDeviceType[],
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    supportsWebAuthN: boolean,
    deleteLastDevice: boolean,
    setAlert: React.Dispatch<React.SetStateAction<any>>,
};

const WebAuthNOverview = (props: WebAuthNOverviewPropType): React.JSX.Element => {

    const {
        t,
        webAuthNDevices,
        setLoading,
        supportsWebAuthN,
        setAlert,
        deleteLastDevice,
    } = props;

    const [createWebAuthNModal, setCreateWebAuthNModal] = React.useState(false);
    const [deleteWebAuthNDevice, setDeleteWebAuthNDevice] = React.useState<webAuthNDeviceType>();
    const [editWebAuthNDevice, setEditWebAuthNDevice] = React.useState<webAuthNDeviceType>();

    const createWebAuthNDeviceShape = getCreateWebAuthNDeviceShape();
    const deleteWebAuthNDeviceShape = getDeleteWebAuthNDeviceShape();
    const editWebAuthNDeviceShape = getEditWebAuthNDeviceShape();

    const handleDeleteWebAuthNModalClose = (): void => {
        setDeleteWebAuthNDevice(undefined);
    };


    const handleEditWebAuthNModalClose = (): void => {
        setEditWebAuthNDevice(undefined);
    };

    const handleCreateWebAuthNDeviceSubmit: SubmitFunction<typeof createWebAuthNDeviceShape> = (values, setErrors): void => {
        if (values!.label) {
            callWithJwt('/customers/self/password-verify', 'POST', {password: values!.password})
                .then(async () => {
                    const device = await createWebAuthNDevice(values!.label as unknown as string)
                        .then(response => response)
                        .catch((reason) => {
                            console.error('Could not create WebAuthN device: ', reason);
                            setAlert({show: true, message: 'account:security.error.webauthn.create', severity: 'error'});
                            setCreateWebAuthNModal(false);
                            setLoading(true);
                        });
                    if (device) {
                        const credential = await createRegistrationCredential(device as any)
                            .then(response => response)
                            .catch((reason) => {
                                console.error('Could not create registration credential: ', reason);
                                callDeleteWebAuthNDevice(device, values!.password as unknown as string)
                                    .then(() => {
                                        setAlert({show: true, message: 'account:security.error.webauthn.create', severity: 'error'});
                                    })
                                    .catch((delete_reason) => {
                                        console.error('Could not delete WebAuthN Device: ', delete_reason);
                                        setAlert({
                                            show: true,
                                            message: 'account:security.error.webauthn.creation_deletion',
                                            severity: 'error',
                                        });
                                    })
                                    .finally(() => {
                                        setCreateWebAuthNModal(false);
                                        setLoading(true);
                                    });
                            });
                        if (credential) {
                            enableWebAuthNDevice({
                                password: values!.password,
                                id: device!.id,
                                credential: credential,
                            }).then((response) => {
                                if (response.data) {
                                    if (response.data.enabled) {
                                        setCreateWebAuthNModal(false);
                                        setLoading(true);
                                        setAlert({show: true, message: 'account:security.webauthn.creation.success', severity: 'success'});
                                    }
                                }
                            }).catch((reason) => {
                                console.error('Could not enable WebAuthN device: ', reason);
                                setAlert({show: true, message: 'account:security.error.webauthn.enable', severity: 'error'});
                                setCreateWebAuthNModal(false);
                                setLoading(true);
                            });
                        }
                    }
                })
                .catch((reason) => {
                    if (reason.data.error.code === 'invalid_password') {
                        console.warn('Given password was invalid');
                        setErrors({password: t('auth:error.invalid_password')});
                    } else {
                        console.error(reason);
                    }
                });
        }
    };


    const handleWebAuthNEdit = (device: any): void => {
        setEditWebAuthNDevice(device);
    };


    const handleWebauthNDelete = (device: any): void => {
        setDeleteWebAuthNDevice(device);
    };

    const handleDeleteWebAuthNSubmit: SubmitFunction<typeof deleteWebAuthNDeviceShape> = (values, setErrors): void => {
        if (deleteWebAuthNDevice) {
            if (values && values!.password) {
                callDeleteWebAuthNDevice(deleteWebAuthNDevice, values.password as unknown as string)
                    .then(() => {
                        setAlert({show: true, message: 'account:security.webauthn.deletion.success', severity: 'success'});
                        setDeleteWebAuthNDevice(undefined);
                        setLoading(true);
                    })
                    .catch((reason) => {
                        console.error('Could not delete WebAuthN device: ', reason);
                        if (reason.data.error.code === 'auth_failed') {
                            setErrors({password: t('auth:error.invalid_password'), password_repeat: t('auth:error.invalid_password')});
                        } else {
                            setAlert({show: true, message: 'account:security.error.webauthn.delete', severity: 'error'});
                            setDeleteWebAuthNDevice(undefined);
                            setLoading(true);
                        }
                    });
            }
        }
    };

    const handleEditWebAuthNDeviceSubmit: SubmitFunction<typeof editWebAuthNDeviceShape> = (values): void => {
        if (editWebAuthNDevice) {
            callWithJwt('/webauthn-devices/' + String(editWebAuthNDevice.id), 'PATCH', {name: values!.label})
                .then(() => {
                    setEditWebAuthNDevice(undefined);
                    setLoading(true);
                })
                .catch((reason) => {
                    console.error('Could not edit WebAuthN Device: ', reason);
                    setAlert({show: true, message: 'account:security.error.webauthn.edit', severity: 'error'});
                })
                .finally(() => {
                    setEditWebAuthNDevice(undefined);
                    setLoading(true);
                    setAlert({show: false, message: '', severity: 'info'});
                });
        }
    };

    const callDeleteWebAuthNDevice = async (device: any, password: string): Promise<any> => {
        if (device) {
            return callWithJwt('/webauthn-devices/' + String(device.id), 'DELETE', {password: password});
        }
        return Promise.reject('No device');
    };

    return <>
        <Card>
            <CardHeader title={t('account:security.webauthn.header')}/>
            <CardContentWithoutPadding>
                <Grid container>
                    <MarginGrid item xs={12}>
                        <Typography>
                            {t('account:security.webauthn.info')}
                        </Typography>
                    </MarginGrid>
                    {webAuthNDevices.length === 0
                        ? <></>
                        : <MarginGrid container spacing={2}>
                            {webAuthNDevices!.map((device, index) => <Grid item xs={12} key={index}>
                                <Card>
                                    <TextWrappableCardHeader title={device.name}
                                                             subheader={t('account:security.created', {when: new Date(device.created).toLocaleDateString(getCurrentLocale())})}
                                                             action={<ActionAreaContainer>
                                                                 <ActionButtonContainer>
                                                                     <IconButtonWithTooltip onClick={() => handleWebAuthNEdit(device)}
                                                                                            label={t('account:security.webauthn.edit')}>
                                                                         <EditOutlined/>
                                                                     </IconButtonWithTooltip>
                                                                     <IconButtonWithTooltip onClick={() => handleWebauthNDelete(device)}
                                                                                            label={t('account:security.webauthn.delete')}>
                                                                         <DeleteOutlined/>
                                                                     </IconButtonWithTooltip>
                                                                 </ActionButtonContainer>
                                                                 <Typography variant="body2">
                                                                     {
                                                                         device.enabled
                                                                             ? t('account:security.device.enabled')
                                                                             : t('account:security.device.disabled')
                                                                     }
                                                                 </Typography>
                                                             </ActionAreaContainer>}/>
                                </Card>
                            </Grid>)}
                        </MarginGrid>
                    }
                    {supportsWebAuthN
                        ? <MarginGrid item xs={12}>
                            <Button onClick={() => setCreateWebAuthNModal(true)}
                                    variant="contained"
                                    fullWidth>
                                {t('account:security.webauthn.add')}
                            </Button>
                        </MarginGrid>
                        : <MarginGrid item xs={12}>
                            <Alert severity="error" variant="outlined">
                                {t('account:security.webauthn.support')}
                            </Alert>
                        </MarginGrid>}
                </Grid>
            </CardContentWithoutPadding>
        </Card>
        <WebAuthNModals t={t}
                        createWebAuthNModal={createWebAuthNModal}
                        createWebAuthNDeviceShape={createWebAuthNDeviceShape}
                        setCreateWebAuthNModal={setCreateWebAuthNModal}
                        handleCreateWebAuthNDeviceSubmit={handleCreateWebAuthNDeviceSubmit}
                        deleteWebAuthNDevice={deleteWebAuthNDevice}
                        deleteWebAuthNDeviceShape={deleteWebAuthNDeviceShape}
                        deleteLastDevice={deleteLastDevice}
                        handleDeleteWebAuthNDeviceClose={handleDeleteWebAuthNModalClose}
                        handleDeleteWebAuthNDeviceSubmit={handleDeleteWebAuthNSubmit}
                        editWebAuthNDevice={editWebAuthNDevice}
                        handleEditWebAuthNDeviceSubmit={handleEditWebAuthNDeviceSubmit}
                        editWebAuthNDeviceShape={editWebAuthNDeviceShape}
                        handleEditWebAuthNDeviceClose={handleEditWebAuthNModalClose}/>
    </>;
};

export default WebAuthNOverview;
