import styled from 'styled-components';
import {CardHeader} from '@mui/material';

const LessPaddedSmallerCardHeader = styled(CardHeader)`
    padding: 0.5rem 1rem 0.25rem 1rem;

    & span {
        font-size: 1.1rem;
    }
`;

export default LessPaddedSmallerCardHeader;
