import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Alert, AlertColor, Card, Grid} from '@mui/material';
import withLoginRequirement from '@/Components/withLoginRequirement';
import CenteredCircularProgress from '@/Components/CenteredCircularProgress';
import {callWithJwt} from '@/Helpers/jwtHelpers';
import TotpOverview from '@/Areas/Customer/Components/TotpOverview';
import WebAuthNOverview from '@/Areas/Customer/Components/WebAuthNOverview';
import CardContentWithoutPadding from '@/Components/Layout/CardContentWithoutPadding';
import {totpDeviceType, totpResponseType, webAuthNDeviceType, webAuthNResponseType} from '@/Areas/Customer/Data/Customer';

const AccountSecurityOverview = (): React.JSX.Element => {
    const {t} = useTranslation(['common', 'account', 'auth', 'formifly']);

    const [loading, setLoading] = React.useState(true);

    const [deleteLastDevice, setDeleteLastDevice] = React.useState(false);
    const [supportsWebAuthN, setSupportsWebAuthN] = React.useState(false);

    const [alert, setAlert] = React.useState({show: false, message: '', severity: 'info'});

    const [totpDevices, setTotpDevices] = React.useState<totpDeviceType[]>([]);
    const [webAuthNDevices, setWebAuthNDevices] = React.useState<webAuthNDeviceType[]>([]);

    const [totpLoadError, setTotpLoadError] = React.useState<boolean>(false);
    const [webAuthNLoadError, setWebAuthNLoadError] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (loading) {
            if (navigator.credentials) {
                setSupportsWebAuthN(true);
            }

            callWithJwt<totpResponseType>('/totp-devices', 'GET')
                .then((response) => {
                    if (response.data.total_count !== 0) {
                        setTotpDevices(response.data.items);
                    } else {
                        setTotpDevices([]);
                    }
                    return Promise.resolve();
                })
                .catch((reason) => {
                    console.error('TOTP Device fetch failed: ', reason);
                    setTotpLoadError(true);
                });

            callWithJwt<webAuthNResponseType>('/webauthn-devices', 'GET')
                .then((response) => {
                    if (response.data.total_count !== 0) {
                        setWebAuthNDevices(response.data.items);
                    } else {
                        setWebAuthNDevices([]);
                    }
                    return Promise.resolve();
                })
                .catch((reason) => {
                    console.error('WebAuthN Device fetch failed: ', reason);
                    setWebAuthNLoadError(true);
                });

            setLoading(false);
        }
    }, [loading]);

    React.useEffect(() => {
        if ((webAuthNDevices.length === 0 && totpDevices.length === 1)
            || (webAuthNDevices.length === 1 && totpDevices.length === 0)) {
            setDeleteLastDevice(true);
        } else {
            setDeleteLastDevice(false);
        }

    }, [webAuthNDevices, totpDevices]);

    React.useEffect(() => {
        if (totpLoadError && !webAuthNLoadError) {
            setAlert({show: true, message: 'account:security.error.totp.fetch', severity: 'error'});
        } else if (webAuthNLoadError && !totpLoadError) {
            setAlert({show: true, message: 'account:security.error.webauthn.fetch', severity: 'error'});
        } else if (totpLoadError && webAuthNLoadError) {
            setAlert({show: true, message: 'account:security.error.fetch', severity: 'error'});
        }
    }, [totpLoadError, webAuthNLoadError]);

    if (loading) {
        return <CenteredCircularProgress/>;
    }

    return <>
        {alert.show &&
            <Card>
                <CardContentWithoutPadding>
                    <Grid item xs={12}>
                        <Alert severity={alert.severity as AlertColor}>
                            <Trans t={t as any} ns="account">{alert.message}</Trans>
                        </Alert>
                    </Grid>
                </CardContentWithoutPadding>
            </Card>}
        <TotpOverview t={t}
                      totpDevices={totpDevices}
                      setTotpDevices={setTotpDevices}
                      setLoading={setLoading}
                      setAlert={setAlert}
                      deleteLastDevice={deleteLastDevice}/>
        <WebAuthNOverview t={t}
                          webAuthNDevices={webAuthNDevices}
                          setLoading={setLoading}
                          supportsWebAuthN={supportsWebAuthN}
                          setAlert={setAlert}
                          deleteLastDevice={deleteLastDevice}/>
    </>;
};

const AccountSecurityOverviewWithHOCs = withLoginRequirement(AccountSecurityOverview);

export default AccountSecurityOverviewWithHOCs;
