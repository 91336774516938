import QrCreator from 'qr-creator';
import React, {useRef} from 'react';

type QRCodePropsType = {
    text?: string;
    cornerRadius?: number;
    ecLevel?: 'L' | 'M' | 'Q' | 'H';
    fillColor?: string;
    backgroundColor?: string | null;
    size?: number;
    children?: string;
};

const QRCode = (props: QRCodePropsType): React.JSX.Element => {
    const canvasElementRef = useRef<HTMLCanvasElement | null>(null);

    if (props.size && props.size > 4096) {
        console.error('Canvas too large for most devices');
    }

    React.useEffect(() => {
        if (canvasElementRef.current) {
            QrCreator.render({
                text: props.text ?? props.children ?? '🪿',
                radius: props.cornerRadius ?? 1,
                ecLevel: props.ecLevel ?? 'L',
                fill: props.fillColor ?? '#000',
                background: props.backgroundColor ?? null,
                size: props.size ?? 256,
            }, canvasElementRef.current);
        }
    }, [props]);

    return <canvas ref={canvasElementRef}>{props.children ?? props.text ?? '🪿'}</canvas>;
};

export default QRCode;
