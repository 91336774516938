import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import accountDe from '@locales/de/account.json';
import authDe from '@locales/de/auth.json';
import chargepointDe from '@locales/de/chargepoint.json';
import commonDe from '@locales/de/common.json';
import connectorDe from '@locales/de/connector.json';
import errorDe from '@locales/de/error.json';
import errorPetsDe from '@locales/de/errorPets.json';
import formiflyDe from '@locales/de/formifly.json';
import footerDe from '@locales/de/footer.json';
import homeDe from '@locales/de/home.json';
import legalDe from '@locales/de/legal.json';
import paymentDe from '@locales/de/payment.json';
import searchDe from '@locales/de/search.json';
import transactionDe from '@locales/de/transaction.json';
import walleeDe from '@locales/de/wallee.json';

import accountEn from '@locales/en/account.json';
import authEn from '@locales/en/auth.json';
import chargepointEn from '@locales/en/chargepoint.json';
import commonEn from '@locales/en/common.json';
import connectorEn from '@locales/en/connector.json';
import errorEn from '@locales/en/error.json';
import errorPetsEn from '@locales/en/errorPets.json';
import formiflyEn from '@locales/en/formifly.json';
import footerEn from '@locales/en/footer.json';
import homeEn from '@locales/en/home.json';
import legalEn from '@locales/en/legal.json';
import searchEn from '@locales/en/search.json';
import paymentEn from '@locales/en/payment.json';
import transactionEn from '@locales/en/transaction.json';
import walleeEn from '@locales/en/wallee.json';

import accountNl from '@locales/nl/account.json';
import authNl from '@locales/nl/auth.json';
import chargepointNl from '@locales/nl/chargepoint.json';
import commonNl from '@locales/nl/common.json';
import connectorNl from '@locales/nl/connector.json';
import errorNl from '@locales/nl/error.json';
import errorPetsNl from '@locales/nl/errorPets.json';
import formiflyNl from '@locales/nl/formifly.json';
import footerNl from '@locales/nl/footer.json';
import homeNl from '@locales/nl/home.json';
import legalNl from '@locales/nl/legal.json';
import paymentNl from '@locales/nl/payment.json';
import searchNl from '@locales/nl/search.json';
import transactionNl from '@locales/nl/transaction.json';
import walleeNl from '@locales/nl/wallee.json';

import accountFr from '@locales/fr/account.json';
import authFr from '@locales/fr/auth.json';
import chargepointFr from '@locales/fr/chargepoint.json';
import commonFr from '@locales/fr/common.json';
import connectorFr from '@locales/fr/connector.json';
import errorFr from '@locales/fr/error.json';
import errorPetsFr from '@locales/fr/errorPets.json';
import formiflyFr from '@locales/fr/formifly.json';
import footerFr from '@locales/fr/footer.json';
import homeFr from '@locales/fr/home.json';
import legalFr from '@locales/fr/legal.json';
import paymentFr from '@locales/fr/payment.json';
import searchFr from '@locales/fr/search.json';
import transactionFr from '@locales/fr/transaction.json';
import walleeFr from '@locales/fr/wallee.json';

import {getItem} from '@/Helpers/localStorageHelpers';

export const resources = {
    de: {
        account: accountDe,
        auth: authDe,
        chargepoint: chargepointDe,
        common: commonDe,
        connector: connectorDe,
        error: errorDe,
        errorPets: errorPetsDe,
        formifly: formiflyDe,
        footer: footerDe,
        legal: legalDe,
        payment: paymentDe,
        search: searchDe,
        transaction: transactionDe,
        wallee: walleeDe,
        home: homeDe,
    },
    en: {
        account: accountEn,
        auth: authEn,
        chargepoint: chargepointEn,
        common: commonEn,
        connector: connectorEn,
        error: errorEn,
        errorPets: errorPetsEn,
        formifly: formiflyEn,
        footer: footerEn,
        legal: legalEn,
        payment: paymentEn,
        search: searchEn,
        transaction: transactionEn,
        wallee: walleeEn,
        home: homeEn,
    },
    nl: {
        account: accountNl,
        auth: authNl,
        chargepoint: chargepointNl,
        common: commonNl,
        connector: connectorNl,
        error: errorNl,
        errorPets: errorPetsNl,
        formifly: formiflyNl,
        footer: footerNl,
        legal: legalNl,
        payment: paymentNl,
        search: searchNl,
        transaction: transactionNl,
        wallee: walleeNl,
        home: homeNl,
    },
    fr: {
        account: accountFr,
        auth: authFr,
        chargepoint: chargepointFr,
        common: commonFr,
        connector: connectorFr,
        error: errorFr,
        errorPets: errorPetsFr,
        formifly: formiflyFr,
        footer: footerFr,
        legal: legalFr,
        payment: paymentFr,
        search: searchFr,
        transaction: transactionFr,
        wallee: walleeFr,
        home: homeFr,
    },
} as const;

export const initPromise = i18n.use(initReactI18next).init({
    lng: getItem('language', null) ?? window.navigator.language.substring(0, 2),
    fallbackLng: 'de',
    debug: false,
    resources: resources,
    ns: ['auth', 'chargepoint', 'common', 'connector', 'error', 'errorPets', 'formifly', 'footer', 'legal', 'wallee'],
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            if (format === 'unpackArray') {
                if (!Array.isArray(value)) {
                    return value as string;
                }
                return value.join(', ');
            }
            if (value instanceof Date) {
                return value.toLocaleString(getLocale(lng));
            }
            return value as string;
        },
    },
});

i18n.on('missingKey', (lng, namespace, key, fallbackValue) => {
    console.warn('Missing translation key', lng, namespace, key, fallbackValue);
});

export const getCurrentLocale = (): string => {
    return getLocale(i18n.language);
};

export const getLocale = (language: string | undefined): string => {
    switch (language) {
        case 'en':
            return 'en-GB';
        case 'de':
            return 'de-DE';
        case 'nl':
            return 'nl-NL';
        case 'fr':
            return 'fr-FR';
        default:
            return 'de-DE';
    }
};

export default i18n;
