import styled from 'styled-components';
import {CardContent} from '@mui/material';

const CardContentWithoutPadding = styled(CardContent)`
    &:last-child {
        padding-bottom: 16px;
    }
`;

export default CardContentWithoutPadding;
