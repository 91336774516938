import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextareaAutosize,
} from '@mui/material';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import styled from 'styled-components';
import {AutoCurrencyMoneyDisplay, TimeDisplay} from '@common/butterfly-shared-react-library';
import {useLocation} from 'react-router-dom';
import i18n, {TFunction} from 'i18next';
import CustomExternalLink from '@/Components/CustomExternalLink';
import WattToKw from '@/Components/DisplayFormatters/WattToKw';
import TableColHeading from '@/Components/Tables/TableColHeading';
import TableRowHeading from '@/Components/Tables/TableRowHeading';
import MoneyTableCell from '@/Components/Tables/MoneyTableCell.jsx';
import {globalConfig} from '@/Helpers/globalConfig';
import {ItemRowPropType, TransactionItemTypeType, TransactionType} from '@/Areas/Transaction/Data/TransactionTypes';
import {getLocale} from '@/Extensions/i18n';

// In general this page and the search one need refactoring
const TransactionReceipt = (): React.JSX.Element => {
    const {uid} = useParams();
    const location = useLocation();

    const [error, setError] = React.useState<undefined | string>();
    const [transaction, setTransaction] = React.useState<TransactionType | undefined>(() => {
        if (location?.state?.transaction && uid === location.state.transaction.uid) {
            return location.state.transaction;
        }

        return undefined;
    });

    const [amountsDiffer, setAmountsDiffer] = React.useState(false);
    const [hasDiscounts, setHasDiscounts] = React.useState(false);
    const [transactionCurrency, setTransactionCurrency] = React.useState<string>('EUR');

    const {t} = useTranslation(['common', 'transaction', 'connector']);

    React.useEffect(() => {
        if (!transaction) {
            const token = new URL(document.URL).searchParams.get('token') ?? undefined;
            fetch((globalConfig.customerApiUrl as string) + '/transactions/' + (uid as string) + '?receipt_token_full=' + (token as string), {})
                .then(async (result) => {
                    const json = await result.json();
                    if (result.ok) {
                        setTransaction(json);
                    } else {
                        return Promise.reject();
                    }
                })
                .catch((reason) => {
                    if (reason?.error?.code) {
                        setError(reason.error.code);
                    } else {
                        setError('unexpected');
                    }
                });
        }
    }, []);

    React.useEffect(() => {
        if (transaction?.items) {
            let foundDiffer = false;
            let foundDiscount = false;
            for (const item of transaction.items) {
                if (item.amount_raw !== item.amount) {
                    foundDiffer = true;
                    if (foundDiscount) {
                        break;
                    }
                }
                if (item.value_gross_discount < 0) {
                    foundDiscount = true;
                    if (foundDiffer) {
                        break;
                    }
                }
            }
            setAmountsDiffer(amountsDiffer);
            setHasDiscounts(foundDiscount);
            if (transaction.currency) {
                setTransactionCurrency(transaction.currency);
            } else {
                setTransactionCurrency('EUR');
            }
        }
    }, [transaction]);

    if (error) {
        return <Alert severity="error">
            <AlertTitle>{t('transaction:error.heading')}</AlertTitle>
            {t('transaction:error.body')}
            <CustomExternalLink href="mailto:support@giro-e.de">{t('transaction:error.support')}</CustomExternalLink>.
        </Alert>;
    }

    if (!transaction) {
        return <CircularProgress/>;
    }

    let authorizationContent = '';
    if (transaction) {
        if (transaction.token.type) {
            // @ts-ignore
            authorizationContent += t('transaction:token.type:' + transaction.token.type);
        }
        if (transaction.token.creditcard_app_label !== undefined) {
            authorizationContent += ' ' + transaction.token.creditcard_app_label;
        }
        if (transaction.token.creditcard_number !== undefined) {
            authorizationContent += ' ' + transaction.token.creditcard_number;
        }
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Card>
                <CardHeader title={t('transaction:heading')}/>
            </Card>
        </Grid>
        <Grid item xs={12} md={6}>
            <Card component="section">
                <CardHeader title={t('transaction:my_charge_point')} component={CardHeading}/>
                <CardContent>
                    <FixedTable size="small">
                        <TableBody>
                            <TableRow>
                                <TableRowHeading>{t('transaction:location')}</TableRowHeading>
                                {transaction.charge_location !== undefined && <WideTableCell>
                                    {transaction.charge_location.address}, {' '}
                                    {transaction.charge_location.postal_code} {' '}
                                    {transaction.charge_location.locality}
                                </WideTableCell>}
                            </TableRow>
                            <TableRow>
                                <TableRowHeading>{t('transaction:EVSE')}</TableRowHeading>
                                {transaction.charge_connector !== undefined &&
                                    <TableCell>{transaction.charge_connector!.uid}</TableCell>}
                            </TableRow>
                            <TableRow>
                                <TableRowHeading>{t('transaction:connector')}</TableRowHeading>
                                {transaction.charge_connector !== undefined && <TableCell>
                                    {t('connector:standard.' + transaction.charge_connector.standard as any)}, {' '}
                                    <WattToKw amount={transaction.charge_connector.power} t={t} perHour={false}/>
                                </TableCell>}
                            </TableRow>
                        </TableBody>
                    </FixedTable>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} md={6}>
            <ContractingPartyCard t={t} transaction={transaction}/>
        </Grid>
        {transaction.status === 'complete'
            ? <>
                <Grid item xs={12}>
                    <Card component="section">
                        <CardHeader title={t('transaction:my_charge')} component={CardHeading}/>
                        <CardContent>
                            <p>{t('transaction:timeframe')}: {new Date(transaction.begin as string).toLocaleString(getLocale(i18n.language))} - {new Date(transaction.end as string).toLocaleString(getLocale(i18n.language))}</p>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableColHeading>{t('transaction:name')}</TableColHeading>
                                        <TableColHeading>{t('transaction:amount_raw')}</TableColHeading>
                                        {amountsDiffer && <TableColHeading>{t('transaction:amount')}</TableColHeading>}
                                        <TableColHeading align="right">
                                            {t('transaction:value_gross_single')}
                                        </TableColHeading>
                                        {hasDiscounts && <>
                                            <TableColHeading align="right">
                                                {t('transaction:value_gross_without_discount')}
                                            </TableColHeading>
                                            <TableColHeading align="right">
                                                {t('transaction:value_gross_discount')}
                                            </TableColHeading>
                                        </>}
                                        <TableColHeading align="right">{t('transaction:value_gross')}</TableColHeading>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transaction.items !== undefined
                                        && transaction.items.length > 0
                                        && transaction.items.map((item: any, index: number) =>
                                            <ItemRow item={item}
                                                     currency={transactionCurrency}
                                                     key={index}
                                                     t={t}
                                                     amountsDiffer={amountsDiffer}
                                                     hasDiscounts={hasDiscounts}/>)}
                                </TableBody>
                            </Table>
                            <br/>
                            <SmallTable size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableRowHeading>{t('transaction:gross')}</TableRowHeading>
                                        <MoneyTableCell amount={transaction.value_gross}
                                                        currency={transactionCurrency}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableRowHeading>{t('transaction:net')}</TableRowHeading>
                                        <MoneyTableCell amount={transaction.value_net} currency={transactionCurrency}/>
                                    </TableRow>
                                    <TableRow>
                                        <TableRowHeading>{t('transaction:tax')}</TableRowHeading>
                                        <MoneyTableCell amount={transaction.value_tax} currency={transactionCurrency}/>
                                    </TableRow>
                                </TableBody>
                            </SmallTable>
                            <p>
                                {transaction.discount_reasons !== undefined
                                    && transaction.discount_reasons.map((reason: any, index: number) =>
                                        <React.Fragment key={index}>{reason}<br/></React.Fragment>)}
                            </p>
                            {transaction.authorization_receipt_path !== undefined
                                /* @ts-ignore */
                                && <MyChargeButton component="a"
                                                   variant="contained"
                                                   target="_blank"
                                                   rel="noreferrer"
                                                   href={
                                                       (globalConfig.customerApiUrlBase as string)
                                                       + (transaction.authorization_receipt_path as string)
                                                   }>
                                    {t('transaction:authorization_receipt')}
                                </MyChargeButton>}
                            {transaction.receipt_path !== undefined
                                /* @ts-ignore */
                                && <MyChargeButton target="_blank"
                                                   rel="noreferrer"
                                                   component="a"
                                                   variant="contained"
                                                   href={(globalConfig.customerApiUrlBase as string) + (transaction.receipt_path as string)}>
                                    {t('transaction:receipt')}
                                </MyChargeButton>}
                        </CardContent>
                    </Card>
                </Grid>
                {(Boolean(transaction.meter_start)
                        || Boolean(transaction.meter_stop)
                        || Boolean(transaction.signed_data_type)
                        || Boolean(transaction.signed_data_values))
                    && <Grid item xs={12}>
                        <Card component="section">
                            <CardHeader title={t('transaction:measured_data')} component={CardHeading}/>
                            <CardContent>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableRowHeading>{t('transaction:meter_begin')}</TableRowHeading>
                                            <TableCell>{transaction.meter_start} {t('common:unit.wh')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableRowHeading>{t('transaction:meter_end')}</TableRowHeading>
                                            <TableCell>{transaction.meter_stop} {t('common:unit.wh')}</TableCell>
                                        </TableRow>
                                        {Boolean(transaction.signed_data_type) &&
                                            <>
                                                <TableRow>
                                                    <TableRowHeading>{t('transaction:signed_data_type')}</TableRowHeading>
                                                    <TableCell>{transaction.signed_data_type as string}</TableCell>
                                                </TableRow>
                                                {Boolean(transaction.signed_data_transaction_identifier) && <TableRow>
                                                    <TableRowHeading>{t('transaction:signed_data_trans_id')}</TableRowHeading>
                                                    <TableCell>{transaction.signed_data_transaction_identifier}</TableCell>
                                                </TableRow>}
                                            </>}
                                        {transaction.signed_data_values !== undefined
                                            && transaction.signed_data_values.map((signedData: any, index: number) =>
                                                <TableRow key={index}>
                                                    <TableRowHeading>
                                                        {/*
                                                    //@ts-ignore */}
                                                        {t('transaction:' + (signedData.event as string))}
                                                    </TableRowHeading>
                                                    <TableCell>
                                                        <SignedDataArea readOnly={true}
                                                                        value={signedData.data}
                                                                        maxRows={10}/>
                                                    </TableCell>
                                                </TableRow>)}
                                    </TableBody>
                                </Table>
                                {Boolean(transaction.signed_data_validation_software_url) &&
                                    <>
                                        <br/>
                                        <p>
                                            {t('transaction:signed_data_validation.start')}
                                            <a href={transaction.signed_data_validation_software_url} target="_blank"
                                               rel="noreferrer">
                                                {t('transaction:signed_data_validation.link')}
                                            </a>
                                            {t('transaction:signed_data_validation.end')}
                                        </p>
                                    </>}
                                <br/>
                                {transaction.signed_data_values !== undefined
                                    && Boolean(transaction.signed_data_path)
                                    && transaction.signed_data_values!.length > 0
                                    && <Button variant="contained"
                                               component="a"
                                               href={(globalConfig.customerApiUrlBase as string) + (transaction.signed_data_path as string)}
                                               target="_blank">
                                        {t('transaction:download_measured_data')}
                                    </Button>}
                            </CardContent>
                        </Card>
                    </Grid>}
                <Grid item xs={12}>
                    <Card component="section">
                        <CardHeader title={t('transaction:identification')} component={CardHeading}/>
                        <CardContent>
                            <Table size="small">
                                <TableBody>
                                    <TableRow>
                                        <TableRowHeading>{t('transaction:identifier')}</TableRowHeading>
                                        <TableCell>{transaction.uid}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableRowHeading>{t('transaction:authorization')}</TableRowHeading>
                                        <TableCell>{authorizationContent}</TableCell>
                                    </TableRow>
                                    {Boolean(transaction.creditcard_merchant_reference_uid) &&
                                        <TableRow>
                                            <TableRowHeading>{t('transaction:authorization_identifier')}</TableRowHeading>
                                            <TableCell>{transaction.creditcard_merchant_reference_uid}</TableCell>
                                        </TableRow>}
                                    {Boolean(transaction.signed_data_transaction_identifier) &&
                                        <TableRow>
                                            <TableRowHeading>{t('transaction:ocpp_transaction_identifier')}</TableRowHeading>
                                            <TableCell>{transaction.signed_data_transaction_identifier}</TableCell>
                                        </TableRow>}
                                    {Boolean(transaction.ocpi_authorization_reference) &&
                                        <TableRow>
                                            <TableRowHeading>{t('transaction:ocpi_authorization_reference')}</TableRowHeading>
                                            <TableCell>{transaction.ocpi_authorization_reference}</TableCell>
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
            </>
            : <Grid item xs={12}>
                <Card component="section">
                    <CardHeader title={t('transaction:my_charge')} component={CardHeading}/>
                    <CardContent>
                        <p>{t('transaction:not_complete')}</p>
                    </CardContent>
                </Card>
            </Grid>}
    </Grid>;
};

const CardHeading = styled.h2`
    margin: 0;
`;

const SmallTable = styled(Table)`
    width: max-content;
    table-layout: fixed;
`;

const FixedTable = styled(Table)`
    table-layout: fixed;
`;

const SignedDataArea = styled(TextareaAutosize)`
    border: 0;
    resize: none;
    overflow: auto;
    background-color ${props => props.theme.palette.secondary.main};
    @media (min-device-width: 650px) {
        width: 30em;
    }
`;

const WideTableCell = styled(TableCell)`
    width: 55%;
`;

const ItemRow = (props: ItemRowPropType): React.JSX.Element => {
    const {item, t, currency, amountsDiffer, hasDiscounts} = props;
    return <TableRow>
        <TableCell>
            <Trans t={t} ns="transaction">
                {item.name}
            </Trans>
        </TableCell>
        <AmountCell type={item.type} amount={item.amount_raw} t={t}/>
        {amountsDiffer && <AmountCell type={item.type} amount={item.amount}/>}
        <TableCell align="right">
            <BasePriceFormatter price={item.value_gross_single} type={item.type} currency={currency} t={t}/>
        </TableCell>
        {hasDiscounts && <>
            <MoneyTableCell amount={item.value_gross_without_discount} currency={currency}/>
            <MoneyTableCell amount={item.value_gross_discount} currency={currency}/>
        </>}
        <MoneyTableCell amount={item.value_gross} currency={currency}/>
    </TableRow>;
};

type AmountCellType = {
    type: TransactionItemTypeType;
    amount: number;
    t?: TFunction;
};

const AmountCell = (props: AmountCellType): React.JSX.Element => {
    const {type, amount, t} = props;
    let display: any;
    if (type === 'energy' && t !== undefined) {
        display = <WattToKw amount={amount} t={t}/>;
    } else if (type === 'duration') {
        display = <TimeDisplay time={amount}/>;
    } else {
        display = amount;
    }

    return <TableCell>{display}</TableCell>;
};

type BasePriceFormatterType = {
    price: number;
    currency: string;
    type: TransactionItemTypeType;
    t: TFunction;
};

const BasePriceFormatter = (props: BasePriceFormatterType): React.JSX.Element => {
    const {price, currency, type, t} = props;
    switch (type) {
        case('energy'):
            return <><AutoCurrencyMoneyDisplay amount={price} currency={currency}/> / {t('unit.kWh')}</>;
        case('duration'):
            return <><AutoCurrencyMoneyDisplay amount={price} currency={currency}/> / h</>;
        default:
            return <AutoCurrencyMoneyDisplay amount={price} currency={currency}/>;
    }
};

const MyChargeButton = styled(Button)`
    margin: 0.5rem;
`;

type ContractingPartyCardType = {
    transaction: TransactionType;
    t: TFunction<['common', 'transaction', 'connector']>;
};

const ContractingPartyCard = (props: ContractingPartyCardType): React.JSX.Element => {
    const {t, transaction} = props;

    if (transaction.creditcard_contractor) {
        // This error is only introduced by using StyledComponents
        // @ts-ignore
        return <FullHeightCard component="section">
            <CardHeader title={t('transaction:contracting_party')} component={CardHeading}/>
            <CardContent>
                {transaction.creditcard_contractor.name}<br/>
                {transaction.creditcard_contractor.address}<br/>
                {transaction.creditcard_contractor.postalcode} {transaction.creditcard_contractor.locality}<br/>
            </CardContent>
        </FullHeightCard>;
    } else if (transaction.creditor_operator) {
        // @ts-ignore
        return <FullHeightCard component="section">
            <CardHeader title={t('transaction:contracting_party')} component={CardHeading}/>
            <CardContent>
                {transaction.creditor_operator.name}<br/>
                {transaction.creditor_operator.address}<br/>
                {transaction.creditor_operator.country} {' '}
                {transaction.creditor_operator.postalcode} {' '}
                {/* The below line was: transaction.creditcard_contractor.locality*/}
                {transaction.creditor_operator.locality}<br/>
            </CardContent>
        </FullHeightCard>;
    } else {
        // @ts-ignore
        return <FullHeightCard component="section">
            <CardHeader title={t('transaction:contracting_party')} component={CardHeading}/>
            <CardContent>
                GLS Gemeinschaftsbank eG<br/>
                Christstraße 9<br/>
                44789 Bochum<br/>
                Email: <CustomExternalLink href="mailto:support@giro-e.de">support@giro-e.de</CustomExternalLink>
            </CardContent>
        </FullHeightCard>;
    }
};

const FullHeightCard = styled(Card)`
    height: 100%;
`;

export default TransactionReceipt;
