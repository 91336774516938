import {getItem, setItem} from '@/Helpers/localStorageHelpers';
import {FetchError, fetchResponseWithRetryAndCast} from '@/Helpers/fetchHelpers';
import {globalConfig} from '@/Helpers/globalConfig';

const requestHeaders = {
    'Content-Type': 'application/json',
};

export const doLoginRequest = async (
    email: string,
    password: string,
    stay_logged_in: boolean = false,
): Promise<{status: number; data?: {access_token?: string}}> => {
    try {
        const response = await fetchResponseWithRetryAndCast<{access_token?: string}>(
            (globalConfig.customerApiUrl as string) + '/auth',
            {
                method: 'POST',
                mode: 'cors',
                headers: requestHeaders,
                credentials: 'include',
                body: JSON.stringify({
                    email: email,
                    password: password,
                    stay_logged_in: stay_logged_in,
                }),
            },
        );

        setItem('stay_logged_in', stay_logged_in);
        return {status: response.response.status, data: response.data};
    } catch (exception) {
        if (exception instanceof FetchError) {
            return {status: exception.response.status};
        }
        return {status: 500};
    }
};

export const doLogoutRequest = (): Promise<{response: Response; data?: any}> => {
    return fetchResponseWithRetryAndCast<Record<string, never>>(
        (globalConfig.customerApiUrl as string) + '/auth/logout',
        {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: requestHeaders,
            body: '{}',
        },
    );
};

export const refreshJwt = async (): Promise<{status: number; data?: {access_token?: string}}> => {
    return fetchResponseWithRetryAndCast<{access_token?: string}>(
        (globalConfig.customerApiUrl as string) + '/auth/refresh',
        {
            method: 'POST',
            mode: 'cors',
            headers: requestHeaders,
            credentials: 'include',
            body: JSON.stringify({
                stay_logged_in: getItem('stay_logged_in', 'false') === 'true',
            }),
        },
    ).then((response) => {
        setItem('jwt', response.data.access_token);
        return {status: response.response.status, data: response.data};
    }).catch((reason) => {
        console.error('Could not refresh jwt', reason);
        return Promise.reject(reason);
    });
};

export const callWithJwt = <T>(
    url: string,
    method: string,
    body?: object,
    additionalHeaders?: object,
    tries = 0,
): Promise<{response: Response, data: T}> => {
    const jwt = getItem('jwt', false);
    if (!jwt) {
        return Promise.reject('No jwt set');
    }

    const requestData: RequestInit = {
        credentials: 'include',
        mode: 'cors',
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + (jwt as string),
            ...additionalHeaders,
        },
    };

    if (method !== 'GET' && body) {
        requestData.body = JSON.stringify(body);
    }

    return fetchResponseWithRetryAndCast<T>((globalConfig.customerApiUrl as string) + url, requestData)
        .catch((reason) => {
            if (tries === 0) {
                if (reason.response && reason.data) {
                    if (reason.response.status === 401 && reason.data.error.code === 'jwt_expired') {
                        return refreshJwt()
                            .then(() => callWithJwt<T>(url, method, body, additionalHeaders, 1))
                            .catch((refreshFailureReason) => {
                                console.warn('Refreshing token failed', refreshFailureReason);
                                return Promise.reject('Not authenticated');
                            });
                    } else {
                        return Promise.reject(reason);
                    }
                } else {
                    return Promise.reject(reason);
                }
            } else {
                return Promise.reject('Not authenticated');
            }
        });
};
